import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { FileBinaryIcon } from '@primer/octicons-react';

import LayoutPortal from '../../components/layout-portal';
import CustomFetch from '../../components/fetch';
import Seo from '../../components/seo';

export default function DashboardPage() {
    const [client, setClient] = useState({});
    const [name, setName] = useState('');
    const [generalDocuments, setGeneralDocuments] = useState([]);
    const [toDos, setToDos] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [videoSource, setVideoSource] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleDownload = (category, type, id) => {
        const postData = category === 'aos' ? { id, type } : {};

        const callbackSuccess = (result) => {
            if (result.url) {
                const supportedVideoFormats = ['mp4', 'webm', 'ogg'];

                if (
                    result.extension &&
                    supportedVideoFormats.includes(
                        result.extension.toLowerCase()
                    )
                ) {
                    setModalShow(true);
                    setVideoSource(result.url);
                } else {
                    // Use Axios to fetch the blob from the result.url
                    axios({
                        url: result.url,
                        method: 'GET',
                        responseType: 'blob', // Important
                    })
                        .then((response) => {
                            saveAs(
                                response.data,
                                `${result.filename}.${result.extension}`
                            ); // Trigger the file download
                        })
                        .catch((error) => {
                            toast.error(
                                'An error occurred while downloading the file.'
                            );
                        });
                }
            } else {
                toast.error(
                    'An issue occurred while trying to download this document.'
                );
            }
        };

        const callbackError = (error) => {
            toast.error(String(error));
        };

        switch (category) {
            case 'generic':
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/portalDocuments/download/${id}`,
                    'POST',
                    {},
                    callbackSuccess,
                    callbackError
                );
                break;
            case 'aos':
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/client/download`,
                    'POST',
                    postData,
                    callbackSuccess,
                    callbackError
                );
                break;
            case 'generalDocument':
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/documents/download/${id}`,
                    'POST',
                    {},
                    callbackSuccess,
                    callbackError
                );
                break;
            default:
                // Handle any other categories or errors
                break;
        }
    };

    const handleApprove = (id) => {
        if (id > 0) {
            withReactContent(Swal)
                .fire({
                    title: 'Approve Document',
                    text: 'Are you sure you want to approve the selected document?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#0276ba',
                    cancelButtonText: 'No',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CustomFetch(
                            `${process.env.GATSBY_API_URL}/documents/${id}`,
                            'PUT',
                            {
                                status: 1,
                                portal: 1,
                            },
                            function (result) {
                                if (result.error !== '') {
                                    toast.error(String(result.error));

                                    let userProfile =
                                        localStorage.getItem('ofpUserProfile');
                                    if (userProfile && userProfile !== '') {
                                        userProfile = JSON.parse(userProfile);
                                        fetchToDo(
                                            userProfile.company_contact_id
                                        );
                                    }
                                } else {
                                    let userProfile =
                                        localStorage.getItem('ofpUserProfile');
                                    if (userProfile && userProfile !== '') {
                                        userProfile = JSON.parse(userProfile);
                                        fetchToDo(
                                            userProfile.company_contact_id
                                        );
                                    }

                                    toast.success(
                                        'The selected document has been approved.'
                                    );
                                }
                            },
                            function (error) {
                                toast.error(String(error));
                            }
                        );
                    }
                });
        }
    };

    const fetchToDo = (id) => {
        if (id > 0) {
            CustomFetch(
                `${process.env.GATSBY_API_URL}/documents/list`,
                'POST',
                {
                    company_contact_id: id,
                },
                function (result) {
                    setToDos(result);
                },
                function (error) {
                    toast.error(String(error));
                }
            );
        }
    };

    const fetchDocuments = () => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/portalDocuments/list`,
            'POST',
            {
                where: [
                    {
                        id: 'archive',
                        value: 0,
                    },
                ],
            },
            function (result) {
                setGeneralDocuments(result);
            },
            function (error) {
                toast.error(String(error));
            }
        );
    };

    const fetchCompany = (id) => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/client/${id}`,
            'GET',
            {},
            function (result) {
                setClient(result);
            },
            function (error) {
                toast.error(String(error));
            }
        );
    };

    useEffect(() => {
        let userProfile = localStorage.getItem('ofpUserProfile');

        if (userProfile && userProfile !== '') {
            userProfile = JSON.parse(userProfile);

            setName(userProfile.firstname + ' ' + userProfile.surname);

            fetchCompany(userProfile.company_contact_id);
            fetchToDo(userProfile.company_contact_id);
        }

        fetchDocuments();
    }, []);

    return (
        <LayoutPortal>
            <Seo title="Dashboard" description="Client Portal - Dashboard" />

            {name !== '' ? (
                <div className="container container--portal">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="portalintro">
                                Hello {name}. You currently have{' '}
                                <a href="/portal/dashboard">
                                    {toDos.length} items for review.
                                </a>
                            </div>

                            <div className="pitem">
                                <div className="plist">
                                    <div className="plist__header">
                                        For Your Review
                                    </div>
                                    <div className="plist__content">
                                        <ul>
                                            {toDos.length > 0 ? (
                                                toDos.map((a) => (
                                                    <li key={`to-do-${a.id}`}>
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                handleDownload(
                                                                    'generalDocument',
                                                                    '',
                                                                    a.id
                                                                );
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {a.label + ' '}
                                                            <small className="date">
                                                                [
                                                                {moment(
                                                                    a.created_at
                                                                ).format(
                                                                    'DD-MM-YYYY'
                                                                )}
                                                                ]
                                                            </small>{' '}
                                                            <span>
                                                                {a.status ===
                                                                0 ? (
                                                                    <small
                                                                        className="sigreq"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();

                                                                            handleApprove(
                                                                                a.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        Approval
                                                                        Required
                                                                    </small>
                                                                ) : null}
                                                                View
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>
                                                    <button>
                                                        No item has been added
                                                        for your review.
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="pitem">
                                <div className="plist">
                                    <div className="plist__header plist__header--alt">
                                        Strategy Information
                                    </div>
                                    <div className="plist__content">
                                        {client.file_1 ||
                                        client.file_2 ||
                                        client.file_3 ||
                                        client.file_4 ||
                                        client.file_5 ||
                                        client.file_6 ||
                                        client.file_7 ||
                                        client.file_8 ? (
                                            <div className="sitems">
                                                {client.file_1 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_1
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_1
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_1',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_1
                                                                ? client.portal_file_1
                                                                : 'File #1'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_2 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_2
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_2
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_2',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_2
                                                                ? client.portal_file_2
                                                                : 'File #2'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_3 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_3
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_3
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_3',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_3
                                                                ? client.portal_file_3
                                                                : 'File #3'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_4 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_4
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_4
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_4',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_4
                                                                ? client.portal_file_4
                                                                : 'File #4'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_5 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_5
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_5
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_5',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />{' '}
                                                            {client.portal_file_5
                                                                ? client.portal_file_5
                                                                : 'File #5'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_6 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_6
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_6
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_6',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_6
                                                                ? client.portal_file_6
                                                                : 'File #6'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_7 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_7
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_7
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_7',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_7
                                                                ? client.portal_file_7
                                                                : 'File #7'}
                                                        </button>
                                                    </div>
                                                )}

                                                {client.file_8 && (
                                                    <div className="sitems__box">
                                                        <button
                                                            style={
                                                                client.file_8
                                                                    ? {}
                                                                    : {
                                                                          cursor: 'not-allowed',
                                                                      }
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (
                                                                    client.file_8
                                                                ) {
                                                                    handleDownload(
                                                                        'aos',
                                                                        'file_8',
                                                                        client.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {client.portal_file_8
                                                                ? client.portal_file_8
                                                                : 'File #8'}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <ul>
                                                <li>
                                                    <button>
                                                        No Strategy Information
                                                        has been added.
                                                    </button>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="pitem">
                                <div className="plist">
                                    <div className="plist__header plist__header--alt">
                                        Other Information
                                    </div>
                                    <div className="plist__content">
                                        <ul>
                                            {generalDocuments.length > 0 ? (
                                                generalDocuments.map((a) => (
                                                    <li
                                                        key={`general-document-${a.id}`}
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                handleDownload(
                                                                    'generic',
                                                                    '',
                                                                    a.id
                                                                );
                                                            }}
                                                        >
                                                            <FileBinaryIcon
                                                                size={24}
                                                            />
                                                            {a.label}
                                                            <span>View</span>
                                                        </button>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>
                                                    <button>
                                                        No other information has
                                                        been added.
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <Modal
                isOpen={modalShow}
                onRequestClose={() => setModalShow(false)}
                overlayClassName={{
                    base: 'overlay-base',
                    afterOpen: 'overlay-after',
                    beforeClose: 'overlay-before',
                }}
                className={{
                    base: 'content-base',
                    afterOpen: 'content-after',
                    beforeClose: 'content-before',
                }}
                closeTimeoutMS={500}
            >
                <>
                    <button
                        onClick={() => setModalShow(false)}
                        className="modalclose"
                    >
                        Close
                    </button>
                    <ReactPlayer url={videoSource} controls={true} />
                </>
            </Modal>
        </LayoutPortal>
    );
}
